import classNames from "classnames";
import classes from "./ticker.module.css";

export const Ticker = () => {
  return (
    <div className={classNames(classes.Roll, "lg")}>
      <p className={classes.RollText}>
        We develop DAO ecosystems, DEXes, NFT marketplaces, DeFi, analytical
        tools, etc.
      </p>
      <p className={classes.RollText}>
        Our team have experience with Polkadot Parachains, EVM chains and
        Bitcoin
      </p>
      <p className={classes.RollText}>
        The core part of our team has been working with blockchain projects
        since 2017
      </p>
      <p className={classes.RollText}>
        The tech stack we work with includes TypeScript, React, Nest.js, Go,
        Solidity, Rust
      </p>
      <p className={classes.RollText}>
        We are advocates of the DevOps/DevSecOps philosophy
      </p>
    </div>
  );
};
