export const LogoSecondary = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1817_773)">
      <path
        d="M41.5384 0.769287C42.8129 0.769287 43.8461 1.80248 43.8461 3.07698C43.8461 4.35148 42.8129 5.38467 41.5384 5.38467C40.2639 5.38467 39.2307 4.35148 39.2307 3.07698C39.2307 1.80248 40.2639 0.769287 41.5384 0.769287Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M41.5386 3.0769H56.154C56.5788 3.0769 56.9232 3.4213 56.9232 3.84614V18.4615"
        stroke="white"
        strokeWidth="5.6"
      />
      <path
        d="M56.9232 16.1538C58.1977 16.1538 59.2309 17.187 59.2309 18.4615C59.2309 19.736 58.1977 20.7692 56.9232 20.7692C55.6486 20.7692 54.6155 19.736 54.6155 18.4615C54.6155 17.187 55.6486 16.1538 56.9232 16.1538Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M18.4615 59.2307C17.187 59.2307 16.1538 58.1976 16.1538 56.923C16.1538 55.6485 17.187 54.6154 18.4615 54.6154C19.736 54.6154 20.7692 55.6485 20.7692 56.923C20.7692 58.1976 19.736 59.2307 18.4615 59.2307Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M18.4615 56.9231H3.84614C3.4213 56.9231 3.0769 56.5787 3.0769 56.1538V41.5385"
        stroke="white"
        strokeWidth="5.6"
      />
      <path
        d="M3.07698 43.8461C1.80248 43.8461 0.769287 42.8129 0.769287 41.5384C0.769287 40.2639 1.80248 39.2307 3.07698 39.2307C4.35148 39.2307 5.38467 40.2639 5.38467 41.5384C5.38467 42.8129 4.35148 43.8461 3.07698 43.8461Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M21.3723 43.0769H27.0498V33.5577H31.1931L36.2805 43.0769H42.548L36.8443 32.6398C39.8994 31.3287 41.5909 28.6669 41.5909 24.9956C41.5909 19.6591 38.0638 16.2238 31.9667 16.2238H21.3723V43.0769ZM27.0498 28.9948V20.8654H30.8784C34.1564 20.8654 35.7429 22.3208 35.7429 24.9956C35.7429 27.6573 34.1564 28.9948 30.9046 28.9948H27.0498Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1817_773">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
