import classNames from "classnames";
import { Github, Rebase, Telegram } from "../../assets/svg";
import classes from "./footer.module.css";

export const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <footer className={classes.Footer}>
      <div className={classes.FooterMain}>
        <Rebase />
        <div className={classes.LinksSide}>
          <div className={classes.LinksBlock}>
            <p className="md gray">Get in touch</p>
            <a
              href="mailto:hello@rebase.agency"
              target="_blank"
              rel="noreferrer"
            >
              <p className="link md">hello@rebase.agency</p>
            </a>
            <a
              href="https://t.me/RebaseBot"
              target="_blank"
              rel="noreferrer"
              className={classNames(classes.LinkWithIcon, "md link")}
            >
              <Telegram />
              <p className="link md">RebaseBot</p>
            </a>
            <a
              href="https://github.com/rebase-agency"
              target="_blank"
              rel="noreferrer"
              className={classNames(classes.LinkWithIcon, "md link")}
            >
              <Github />
              <p className="link md">Rebase</p>
            </a>
          </div>
          <div className={classes.LinksBlock}>
            <p className="md gray">Information</p>
            <a href="/privacy-policy">
              <p className="link md">Privacy</p>
            </a>
            <a href="/cookie-policy">
              <p className="link md">Cookies</p>
            </a>
            <a
              href="https://status.rebase.agency/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="link md">Status</p>
            </a>
          </div>
        </div>
      </div>
      <p className={classNames(classes.Copyrights, "md gray")}>
        © {date} Rebase
      </p>
    </footer>
  );
};
