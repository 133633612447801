import { Field, Formik, Form } from "formik";
import classes from "./contact-form.module.css";
import { boolean, object, string } from "yup";
import classNames from "classnames";
import { apiInstance } from "../../../shared/api";

export const ContactForm = ({ complete }: { complete: () => void }) => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
    agreedWithPrivacyPolicy: false,
  };

  const validationSchema = object().shape({
    name: string().required("Your name is required"),
    email: string().email("Invalid email format").required("Email is required"),
    phone: string().required("Phone is required"),
    company: string().required("Company name is required"),
    message: string().required("Description is required"),
    agreedWithPrivacyPolicy: boolean().oneOf(
      [true],
      "You must agree to the Privacy Policy",
    ),
  });
  return (
    <main className={classes.Main}>
      <h5 className="h5">Fill out the form and we will contact you shortly</h5>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          apiInstance
            .post("/feedback", values)
            .then(() => {
              complete();
            })
            .catch((e) => {
              console.error(e);
            });
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className={classes.Form}>
              <div className={classes.InputsBlock}>
                <Field
                  type="text"
                  name="name"
                  placeholder="Your name*"
                  className={classNames(
                    classes.Input,
                    errors.name && touched.name ? classes.InputError : "",
                  )}
                />
                <Field
                  type="email"
                  name="email"
                  placeholder="Email*"
                  className={classNames(
                    classes.Input,
                    errors.email && touched.email ? classes.InputError : "",
                  )}
                />
                <Field
                  type="tel"
                  name="phone"
                  placeholder="Phone*"
                  className={classNames(
                    classes.Input,
                    errors.phone && touched.phone ? classes.InputError : "",
                  )}
                />
                <Field
                  type="text"
                  name="company"
                  placeholder="Company*"
                  className={classNames(
                    classes.Input,
                    errors.company && touched.company ? classes.InputError : "",
                  )}
                />
                <Field
                  as="textarea"
                  name="message"
                  rows="5"
                  placeholder="Describe a task or ask your questions*"
                  className={classNames(
                    classes.Input,
                    classes.Textarea,
                    errors.message && touched.message ? classes.InputError : "",
                  )}
                />
              </div>
              <div className={classes.Agreement}>
                <label className={classes.Label}>
                  <Field
                    type="checkbox"
                    name="agreedWithPrivacyPolicy"
                    className={
                      errors.agreedWithPrivacyPolicy
                        ? classes.AgreementError
                        : ""
                    }
                  />
                  <span className={classes.CheckBox}></span>{" "}
                  <p>
                    I agree to the{" "}
                    <a href="/privacy-policy" className={classes.Link}>
                      Privacy Policy
                    </a>
                  </p>
                </label>
              </div>
              <button type="submit" className={classes.Button}>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </main>
  );
};
