import { useState } from "react";
import { CloseIcon } from "../../assets/svg";
import { LogoSecondary } from "../../assets/svg";
import { ContactForm } from "./ContactForm";
import classes from "./contact.module.css";
import { ContactResult } from "./ContactResult";

export const Contact = ({ close }: { close: () => void }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.ContactForm}>
      <header className={classes.Header}>
        <a href="/" className={classes.Logo}>
          <LogoSecondary />
        </a>
        <button className={classes.Close} onClick={close}>
          <CloseIcon />
        </button>
      </header>
      {!open && <ContactForm complete={() => setOpen(true)} />}
      {open && <ContactResult status={true} />}
    </div>
  );
};
