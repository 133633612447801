import { Helmet } from "react-helmet";
import { Layout } from "../../../components/Layout";
import classes from "./../policies.module.css";

export const CookiePolicy = () => {
  return (
    <>
      <Helmet>
        <title>Rebase | Cookie policy</title>
        <meta property="og:title" content="Rebase | Cookie policy" />
        <meta name="twitter:title" content="Rebase | Cookie policy" />
      </Helmet>
      <Layout>
        <div className={classes.PolicyWrap}>
          <header className={classes.Header}>
            <h3 className="h3">Cookie Policy</h3>
            <div>
              <p className="sm">
                <strong>Last updated September 12, 2023</strong>
              </p>
              <p className="sm">that include Standard Contractual Clauses</p>
            </div>
          </header>
          <section className={classes.Section}>
            <p className="sm">
              This Cookie Policy explains how Rebase (&#34;
              <strong>Company</strong>&#34;, &#34;
              <strong>we</strong>&#34;, &#34;
              <strong>us</strong>&#34;, or &#34;
              <strong>our</strong>&#34;) uses cookies and similar technologies
              to recognize you when you visit our website at
              <a
                className={classes.Link}
                href="https://rebase.agency"
                target="_blank"
                rel="noreferrer"
              ></a>{" "}
              (&#34;Website&#34;). It explains what these technologies are and
              why we use them, as well as your rights to control our use of
              them.
            </p>
            <p className="sm">
              In some cases we may use cookies to collect personal information,
              or that becomes personal information if we combine it with other
              information.
            </p>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">What are cookies?</h4>
            <p className="sm">
              Cookies are small data files that are placed on your computer or
              mobile device when you visit a website. Cookies are widely used by
              website owners in order to make their websites work, or to work
              more efficiently, as well as to provide reporting information.
            </p>
            <p className="sm">
              Cookies set by the website owner are called &#34;first-party
              cookies.&#34; Cookies set by parties other than the website owner
              are called &#34;third-party cookies.&#34; Third-party cookies
              enable third-party features or functionality to be provided on or
              through the website (e.g., advertising, interactive content, and
              analytics). The parties that set these third-party cookies can
              recognize your computer both when it visits the website in
              question and also when it visits certain other websites.
            </p>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">Why do we use cookies?</h4>
            <p className="sm">
              We use first- and third-party cookies for several reasons. Some
              cookies are required for technical reasons in order for our
              Website to operate, and we refer to these as &#34;essential&#34;
              or &#34;strictly necessary&#34; cookies. Other cookies also enable
              us to track and target the interests of our users to enhance the
              experience on our Online Properties. Third parties serve cookies
              through our Website for advertising, analytics, and other
              purposes. This is described in more detail below.
            </p>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">How can I control cookies?</h4>
            <p className="sm">
              You have the right to decide whether to accept or reject cookies.
              You can exercise your cookie rights by setting your preferences in
              the Cookie Consent Manager. The Cookie Consent Manager allows you
              to select which categories of cookies you accept or reject.
              Essential cookies cannot be rejected as they are strictly
              necessary to provide you with services.
            </p>
            <p className="sm">
              The Cookie Consent Manager can be found in the notification banner
              and on our website. If you choose to reject cookies, you may still
              use our website though your access to some functionality and areas
              of our website may be restricted. You may also set or amend your
              web browser controls to accept or refuse cookies.
            </p>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">How can I control cookies on my browser?</h4>
            <p>
              As the means by which you can refuse cookies through your web
              browser controls vary from browser to browser, you should visit
              your browser&#39;s help menu for more information. The following
              is information about how to manage cookies on the most popular
              browsers (click on a browser’s name to follow the link):
            </p>
            <ul>
              <li>
                <a
                  className={classes.Link}
                  href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
                  target="_blank"
                  rel="noreferrer"
                >
                  Chrome
                </a>
              </li>
              <li>
                <a
                  className={classes.Link}
                  href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  target="_blank"
                  rel="noreferrer"
                >
                  Internet Explorer
                </a>
              </li>
              <li>
                <a
                  className={classes.Link}
                  href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Firefox
                </a>
              </li>
              <li>
                <a
                  className={classes.Link}
                  href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                  target="_blank"
                  rel="noreferrer"
                >
                  Safari
                </a>
              </li>
              <li>
                <a
                  className={classes.Link}
                  href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                  target="_blank"
                  rel="noreferrer"
                >
                  Edge
                </a>
              </li>
              <li>
                <a
                  className={classes.Link}
                  href="https://help.opera.com/en/latest/web-preferences/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Opera
                </a>
              </li>
            </ul>
            <p className="sm">
              In addition, most advertising networks offer you a way to opt out
              of targeted advertising. If you would like to find out more
              information, please visit:
            </p>
            <ul>
              <li>
                <a
                  className={classes.Link}
                  href="http://www.aboutads.info/choices/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Digital Advertising Alliance
                </a>
              </li>
              <li>
                <a
                  className={classes.Link}
                  href="https://youradchoices.ca/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Digital Advertising Alliance of Canada
                </a>
              </li>
              <li>
                <a
                  className={classes.Link}
                  href="http://www.youronlinechoices.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  European Interactive Digital Advertising Alliance
                </a>
              </li>
            </ul>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">
              What about other tracking technologies, like web beacons?
            </h4>
            <p className="sm">
              Cookies are not the only way to recognize or track visitors to a
              website. We may use other, similar technologies from time to time,
              like web beacons (sometimes called &#34;tracking pixels&#34; or
              &#34;clear gifs&#34;). These are tiny graphics files that contain
              a unique identifier that enables us to recognize when someone has
              visited our Website or opened an email including them. This allows
              us, for example, to monitor the traffic patterns of users from one
              page within a website to another, to deliver or communicate with
              cookies, to understand whether you have come to the website from
              an online advertisement displayed on a third-party website, to
              improve site performance, and to measure the success of email
              marketing campaigns. In many instances, these technologies are
              reliant on cookies to function properly, and so declining cookies
              will impair their functioning.
            </p>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">
              Do you use Flash cookies or Local Shared Objects?
            </h4>
            <p className="sm">
              Websites may also use so-called &#34;Flash Cookies&#34; (also
              known as Local Shared Objects or &#34;LSOs&#34;) to, among other
              things, collect and store information about your use of our
              services, fraud prevention, and for other site operations.
            </p>
            <p className="sm">
              If you do not want Flash Cookies stored on your computer, you can
              adjust the settings of your Flash player to block Flash Cookies
              storage using the tools contained in the{" "}
              <a
                className={classes.Link}
                href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                target="_blank"
                rel="noreferrer"
              >
                Website Storage Settings Panel
              </a>
              . You can also control Flash Cookies by going to the{" "}
              <a
                className={classes.Link}
                href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                target="_blank"
                rel="noreferrer"
              >
                Global Storage Settings Panel
              </a>{" "}
              and following the instructions (which may include instructions
              that explain, for example, how to delete existing Flash Cookies
              (referred to &#34;information&#34; on the Macromedia site), how to
              prevent Flash LSOs from being placed on your computer without your
              being asked, and (for Flash Player 8 and later) how to block Flash
              Cookies that are not being delivered by the operator of the page
              you are on at the time).
            </p>
            <p className="sm">
              Flash Cookies that are not being delivered by the operator of the
              page you are on at the time).
            </p>
            <p className="sm">
              Please note that setting the Flash Player to restrict or limit
              acceptance of Flash Cookies may reduce or impede the functionality
              of some Flash applications, including, potentially, Flash
              applications used in connection with our services or online
              content.
            </p>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">Do you serve targeted advertising?</h4>
            <p className="sm">
              Third parties may serve cookies on your computer or mobile device
              to serve advertising through our Website. These companies may use
              information about your visits to this and other websites in order
              to provide relevant advertisements about goods and services that
              you may be interested in. They may also employ technology that is
              used to measure the effectiveness of advertisements. They can
              accomplish this by using cookies or web beacons to collect
              information about your visits to this and other sites in order to
              provide relevant advertisements about goods and services of
              potential interest to you. The information collected through this
              process does not enable us or them to identify your name, contact
              details, or other details that directly identify you unless you
              choose to provide these.
            </p>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">
              How often will you update this Cookie Policy?
            </h4>
            <p className="sm">
              We may update this Cookie Policy from time to time in order to
              reflect, for example, changes to the cookies we use or for other
              operational, legal, or regulatory reasons. Please therefore
              revisit this Cookie Policy regularly to stay informed about our
              use of cookies and related technologies.
            </p>
            <p className="sm">
              The date at the top of this Cookie Policy indicates when it was
              last updated.
            </p>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">Where can I get further information?</h4>
            <p className="sm">
              If you have any questions about our use of cookies or other
              technologies, please email us at{" "}
              <a className={classes.Link} href="mailto:privacy@rebase.agency">
                privacy@rebase.agency
              </a>
              .
            </p>
          </section>
          <section className={classes.Section}>
            <p className="sm bold">
              <a className={classes.Link} href="/privacy-policy">
                To the privacy policy page
              </a>
            </p>
          </section>
        </div>
      </Layout>
    </>
  );
};
