import React, {
  createContext,
  JSX,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import gsap from "gsap";
import classes from "../app.module.css";

interface ICursorContext {
  onMouseMove: (e: MouseEvent) => void;
  onOver: () => void;
  onOut: () => void;
  setOpenedContactsForm: (v: boolean) => void;
}

export const CursorContext = createContext<ICursorContext | null>(null);

export const CursorProvider = ({ children }: { children: JSX.Element }) => {
  const cursorCircle = useRef() as RefObject<HTMLDivElement>;
  const cursorText = useRef() as RefObject<HTMLParagraphElement>;
  const [openedContactForm, setOpenedContactsForm] = useState(false);

  const onMouseMove = (e: MouseEvent) => {
    gsap.to(cursorCircle.current, {
      x: e.clientX,
      y: e.clientY,
      duration: 0.4,
    });
  };

  const onOver = () => {
    gsap.to(cursorCircle.current, {
      scale: 1,
      duration: 0.5,
    });
    gsap.to(cursorText.current, {
      opacity: 1,
      scale: 1,
      duration: 0.5,
    });
  };

  const onOut = () => {
    gsap.to(cursorCircle.current, {
      scale: 0.16,
      duration: 0.5,
    });
    gsap.to(cursorText.current, {
      opacity: 0,
      scale: 0,
      duration: 0.5,
    });
  };

  useEffect(() => {
    document.body.addEventListener("mousemove", onMouseMove);

    return () => {
      document.body.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  useEffect(() => {
    if (cursorCircle.current) {
      cursorCircle.current.style.background = openedContactForm
        ? "#fff"
        : "#000";
    }
  }, [openedContactForm]);

  return (
    <CursorContext.Provider
      value={{ onOver, onOut, onMouseMove, setOpenedContactsForm }}
    >
      <div ref={cursorCircle} className={classes.Circle}>
        <p ref={cursorText} className={classes.InnerText}>
          View
        </p>
      </div>
      {children}
    </CursorContext.Provider>
  );
};
