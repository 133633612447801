import { Card } from "../Card";
import classes from "./card-blocks.module.css";
import { useCases } from "../../hooks/useCases";

export const CardsBlock = () => {
  const cases = useCases();
  return (
    <div className={classes.CardsBlock}>
      {cases.items.map((card, index) => (
        <Card key={index} data={card} />
      ))}
    </div>
  );
};
