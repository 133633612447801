export const ArrowRight = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.7617 13.3383L13.4312 4.37227C13.352 4.30391 13.2508 4.26562 13.1441 4.26562H10.7242C10.5219 4.26562 10.4289 4.51719 10.582 4.64844L20.1578 12.9609H4.15625C4.03594 12.9609 3.9375 13.0594 3.9375 13.1797V14.8203C3.9375 14.9406 4.03594 15.0391 4.15625 15.0391H20.1551L10.5793 23.3516C10.4262 23.4855 10.5191 23.7344 10.7215 23.7344H13.2234C13.2754 23.7344 13.3273 23.7152 13.3656 23.6797L23.7617 14.6617C23.8564 14.5794 23.9323 14.4777 23.9843 14.3636C24.0363 14.2494 24.0632 14.1254 24.0632 14C24.0632 13.8746 24.0363 13.7506 23.9843 13.6364C23.9323 13.5223 23.8564 13.4206 23.7617 13.3383Z"
      fill="white"
    />
  </svg>
);
