export const Github = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1855_61)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9672 0C4.90263 0 0 4.93878 0 11.0487C0 15.9327 3.14129 20.0669 7.49908 21.5301C8.04392 21.6401 8.24349 21.2924 8.24349 20.9999C8.24349 20.7438 8.22553 19.8658 8.22553 18.951C5.17471 19.6096 4.53941 17.6339 4.53941 17.6339C4.04912 16.3534 3.32267 16.0243 3.32267 16.0243C2.32414 15.3475 3.39541 15.3475 3.39541 15.3475C4.50304 15.4207 5.08425 16.4816 5.08425 16.4816C6.06459 18.1644 7.64433 17.6889 8.27986 17.3962C8.37055 16.6827 8.66127 16.1889 8.96994 15.9145C6.53669 15.6584 3.97661 14.7072 3.97661 10.4632C3.97661 9.25594 4.41212 8.26818 5.1022 7.49998C4.99333 7.22565 4.61192 6.09131 5.21131 4.57308C5.21131 4.57308 6.13733 4.28035 8.22531 5.7072C9.11924 5.46535 10.0411 5.34232 10.9672 5.34129C11.8932 5.34129 12.8372 5.46947 13.7089 5.7072C15.7971 4.28035 16.7231 4.57308 16.7231 4.57308C17.3225 6.09131 16.9409 7.22565 16.832 7.49998C17.5403 8.26818 17.9578 9.25594 17.9578 10.4632C17.9578 14.7072 15.3978 15.64 12.9463 15.9145C13.3459 16.262 13.6907 16.9205 13.6907 17.9632C13.6907 19.4449 13.6728 20.634 13.6728 20.9997C13.6728 21.2924 13.8726 21.6401 14.4172 21.5304C18.775 20.0667 21.9163 15.9327 21.9163 11.0487C21.9342 4.93878 17.0136 0 10.9672 0Z"
        fill="#24292F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1855_61">
        <rect width="22" height="21.551" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
