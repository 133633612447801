import { Route, Routes } from "react-router-dom";
import { MainPage } from "../pages/mainPage";
import { PrivacyPolicy } from "../pages/policies/PrivacyPolicy";
import { CookiePolicy } from "../pages/policies/CookiePolisy";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
    </Routes>
  );
};
