import { Helmet } from "react-helmet";
import { Layout } from "../../../components/Layout";
import classes from "./../policies.module.css";

export const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Rebase | Privacy policy</title>
        <meta property="og:title" content="Rebase | Privacy policy" />
        <meta name="twitter:title" content="Rebase | Privacy policy" />
      </Helmet>
      <Layout>
        <div className={classes.PolicyWrap}>
          <header className={classes.Header}>
            <h3 className="h3">Privacy Policy</h3>
            <div>
              <p className="sm">
                <strong>Last updated September 12, 2023</strong>
              </p>
              <p className="sm">that include Standard Contractual Clauses</p>
            </div>
          </header>
          <section className={classes.Section}>
            <p className="sm">
              Thank you for choosing to be part of our community at Rebase
              (&#34;
              <strong>Company</strong>&#34;, &#34;
              <strong>we</strong>&#34;, &#34;
              <strong>us</strong>&#34;, or &#34;
              <strong>our</strong>&#34;). We are committed to protecting your
              personal information and your right to privacy. If you have any
              questions or concerns about our policy, or our practices with
              regards to your personal information, please contact us at{" "}
              <a href="mailto:privacy@rebase.agency" className={classes.Link}>
                privacy@rebase.agency
              </a>
              .
            </p>
            <p className="sm">
              When you visit our website{" "}
              <a
                href="https://rebase.agency"
                className={classes.Link}
                target="_blank"
                rel="noreferrer"
              >
                https://rebase.agency
              </a>
              , and use our services, you trust us with your personal
              information. We take your privacy very seriously. In this privacy
              policy, we describe our privacy policy. We seek to explain to you
              in the clearest way possible what information we collect, how we
              use it and what rights you have in relation to it. We hope you
              take some time to read through it carefully, as it is important.
              If there are any terms in this privacy policy that you do not
              agree with, please discontinue use of our Sites and our services.
            </p>
            <p className="sm">
              This privacy policy applies to all information collected through
              our website (such as{" "}
              <a
                href="https://rebase.agency"
                className={classes.Link}
                target="_blank"
                rel="noreferrer"
              >
                https://rebase.agency
              </a>
              ), and/or any related services, sales, marketing or events (we
              refer to them collectively in this privacy policy as the &#34;
              <strong>Services</strong>&#34;).
            </p>
            <p className="sm">
              <strong>
                Please read this privacy policy carefully as it will help you
                make informed decisions about sharing your personal information
                with us.
              </strong>
            </p>
          </section>
          <section className={classes.Section}>
            <h4 className="h4">TABLE OF CONTENTS</h4>
            <ol className={classes.Table}>
              <li className={classes.InnerList}>
                <a href="#information-we-collect" className={classes.Link}>
                  WHAT INFORMATION DO WE COLLECT?
                </a>
                <ul>
                  <li>
                    <a href="#self-disclose" className={classes.Link}>
                      Personal information you disclose to us
                    </a>
                  </li>
                  <li>
                    <a href="#automaticaly-collected" className={classes.Link}>
                      Information automatically collected
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#information-usage" className={classes.Link}>
                  HOW DO WE USE YOUR INFORMATION?
                </a>
              </li>
              <li>
                <a href="#information-sharing" className={classes.Link}>
                  WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </a>
              </li>
              <li>
                <a href="#information-sharing-parties" className={classes.Link}>
                  WHO WILL YOUR INFORMATION BE SHARED WITH?
                </a>
              </li>
              <li>
                <a href="#cookies" className={classes.Link}>
                  DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </a>
              </li>
              <li>
                <a href="#information-keeping" className={classes.Link}>
                  HOW LONG DO WE KEEP YOUR INFORMATION?
                </a>
              </li>
              <li>
                <a href="#safety" className={classes.Link}>
                  HOW DO WE KEEP YOUR INFORMATION SAFE?
                </a>
              </li>
              <li>
                <a href="#minors" className={classes.Link}>
                  DO WE COLLECT INFORMATION FROM MINORS?
                </a>
              </li>
              <li>
                <a href="#rights" className={classes.Link}>
                  WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </li>
              <li>
                <a href="#data-breach" className={classes.Link}>
                  DATA BREACH
                </a>
              </li>
              <li>
                <a href="#do-not-truck" className={classes.Link}>
                  CONTROLS FOR DO-NOT-TRACK FEATURES
                </a>
              </li>
              <li>
                <a href="#california" className={classes.Link}>
                  DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </li>
              <li>
                <a href="#updates" className={classes.Link}>
                  DO WE MAKE UPDATES TO THIS POLICY?
                </a>
              </li>
              <li>
                <a href="#contacts" className={classes.Link}>
                  HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                </a>
              </li>
            </ol>
          </section>
          <section className={classes.Section} id="information-we-collect">
            <h4 className="h4">1. WHAT INFORMATION DO WE COLLECT?</h4>
            <h6 className="h6" id="self-disclose">
              Personal information you disclose to us
            </h6>
            <p className="sm">
              <strong>In Short:</strong> We collect personal information that
              you provide to us such as name, address, contact information,
              passwords and security data
            </p>
            <p className="sm">
              We collect personal information that you voluntarily provide to us
              when expressing an interest in obtaining information about us or
              our products and services, when participating in activities on the
              Services or otherwise contacting us.
            </p>
            <p className="sm">
              The personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make
              and the products and features you use. The personal information we
              collect can include the following:
            </p>
            <p className="sm">
              <strong>Name and Contact Data.</strong> We collect your first and
              last name, email address, postal address, phone number, and other
              similar contact data.
            </p>
            <p className="sm">
              <strong>Credentials.</strong> We collect passwords, password
              hints, and similar security information used for authentication
              and account access.
            </p>
            <p className="sm">
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <h6 className="h6" id="automaticaly-collected">
              Information automatically collected
            </h6>
            <p className="sm">
              <strong>In Short:</strong> Some information – such as IP address
              and/or browser and device characteristics – is collected
              automatically when you visit our Services.
            </p>
            <p className="sm">
              We automatically collect certain information when you visit, use
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p className="sm">
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
          </section>
          <section className={classes.Section} id="information-usage">
            <h4 className="h4">2. HOW DO WE USE YOUR INFORMATION?</h4>
            <p className="sm">
              <strong>In Short:</strong> We process your information for
              purposes based on legitimate business interests, the fulfillment
              of our contract with you, compliance with our legal obligations,
              and/or your consent.
            </p>
            <p className="sm">
              We use personal information collected via our Services for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </p>
            <p className="sm">We use the information we collect or receive:</p>
            <h6 className="h6">
              To send you marketing and promotional communications.
            </h6>
            <p className="sm">
              We and/or our third party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. You can opt-out of
              our marketing emails at any time (see the &#34;WHAT ARE YOUR
              PRIVACY RIGHTS&#34; below).
            </p>
            <h6 className="h6">To send administrative information to you.</h6>
            <p className="sm">
              We may use your personal information to send you product, service
              and new feature information and/or information about changes to
              our terms, conditions, and policies.
            </p>
            <h6 className="h6">Fulfill and manage your orders.</h6>
            <p className="sm">
              We may use your information to fulfill and manage your orders,
              payments, returns, and exchanges made through the Services.
            </p>
            <h6 className="h6">
              To respond to user inquiries/offer support to users.
            </h6>
            <p className="sm">
              We may use your information to respond to your inquiries and solve
              any potential issues you might have with the use of our Services.
              We may use and store this information in aggregated and anonymized
              form so that it is not associated with individual end users and
              does not include personal information. We will not use
              identifiable personal information without your consent.
            </p>
          </section>
          <section className={classes.Section} id="information-sharing">
            <h4 className="h4">
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </h4>
            <p className="sm">
              <strong>In Short:</strong> We only share information with your
              consent, to comply with laws, to provide you with services, to
              protect your rights, or to fulfill business obligations.
            </p>
            <p className="sm">
              We may process or share data based on the following legal basis:
            </p>
            <h6 className="h6">Consent</h6>
            <p className="sm">
              We may process your data if you have given us specific consent to
              use your personal information for a specific purpose.
            </p>
            <h6 className="h6">Legitimate Interests</h6>
            <p className="sm">
              We may process your data when it is reasonably necessary to
              achieve our legitimate business interests.
            </p>
            <h6 className="h6">Performance of a Contract</h6>
            <p className="sm">
              Where we have entered into a contract with you, we may process
              your personal information to fulfill the terms of our contract.
            </p>
            <h6 className="h6">Legal Obligations</h6>
            <p className="sm">
              We may disclose your information where we are legally required to
              do so in order to comply with applicable law, governmental
              requests, a judicial proceeding, court order, or legal process,
              such as in response to a court order or a subpoena (including in
              response to public authorities to meet national security or law
              enforcement requirements).
            </p>
            <h6 className="h6">Vital Interests</h6>
            <p className="sm">
              We may disclose your information where we believe it is necessary
              to investigate, prevent, or take action regarding potential
              violations of our policies, suspected fraud, situations involving
              potential threats to the safety of any person and illegal
              activities, or as evidence in litigation in which we are involved.
            </p>
            <h6 className="h6">
              Vendors, Consultants and Other Third-Party Service Providers
            </h6>
            <p className="sm">
              We may share your data with third party vendors, service
              providers, contractors or agents who perform services for us or on
              our behalf and require access to such information to do that work.
              Examples include: payment processing, data analysis, email
              delivery, hosting services, customer service and marketing
              efforts. We may allow selected third parties to use tracking
              technology on the Services, which will enable them to collect data
              about how you interact with the Services over time. This
              information may be used to, among other things, analyze and track
              data, determine the popularity of certain content and better
              understand online activity. Unless described in this Policy, we do
              not share, sell, rent or trade any of your information with third
              parties for their promotional purposes. We have contracts in place
              with our data processors. This means that they cannot do anything
              with your personal information unless we have instructed them to
              do it. They will not share your personal information with any
              organization apart from us. They will hold it securely and retain
              it for the period we instruct.
            </p>
            <h6 className="h6">Business Transfers</h6>
            <p className="sm">
              We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business to
              another company.
            </p>
          </section>
          <section className={classes.Section} id="information-sharing-parties">
            <h4 className="h4">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h4>
            <p className="sm">
              <strong>In Short:</strong> We only share information with the
              following third parties.
            </p>
            <p className="sm">
              We only share and disclose your information with the following
              third parties. We have categorized each party so that you may
              easily understand the purpose of our data collection and
              processing practices. If we have processed your data based on your
              consent and you wish to revoke your consent, please contact us.
            </p>
            <h6 className="h6">Content Optimization</h6>
            <ul>
              <li>YouTube video embed</li>
            </ul>
          </section>
          <section className={classes.Section} id="cookies">
            <h4 className="h4">
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h4>
            <p className="sm">
              <strong>In Short:</strong> We may use cookies and other tracking
              technologies to collect and store your information.
            </p>
            <p className="sm">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our{" "}
              <a href="/cookie-policy" className={classes.Link}>
                Cookie Policy
              </a>
              .
            </p>
          </section>
          <section className={classes.Section} id="information-keeping">
            <h4 className="h4">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
            <p className="sm">
              <strong>In Short:</strong>We keep your information for as long as
              necessary to fulfill the purposes outlined in this privacy policy
              unless otherwise required by law.
            </p>
            <p className="sm">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy policy, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              policy will require us keeping your personal information for
              longer than 2 years.
            </p>
            <p className="sm">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize it, or,
              if this is not possible (for example, because your personal
              information has been stored in backup archives), then we will
              securely store your personal information and isolate it from any
              further processing until deletion is possible.
            </p>
          </section>
          <section className={classes.Section} id="safety">
            <h4 className="h4">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
            <p className="sm">
              <strong>In Short:</strong> We aim to protect your personal
              information through a system of organizational and technical
              security measures.
            </p>
            <p className="sm">
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, please also remember that we
              cannot guarantee that the internet itself is 100% secure. Although
              we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the services within a
              secure environment.
            </p>
          </section>
          <section className={classes.Section} id="minors">
            <h4 className="h4">8. DO WE COLLECT INFORMATION FROM MINORS?</h4>
            <p className="sm">
              <strong>In Short:</strong> We do not knowingly collect data from
              or market to children under 18 years of age.
            </p>
            <p className="sm">
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we have collected from children
              under age 18, please contact us at privacy@rebase.agency.
            </p>
          </section>
          <section className={classes.Section} id="rights">
            <h4 className="h4">9. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
            <p className="sm">
              <strong>In Short:</strong> In some regions, such as the European
              Economic Area, you have rights that allow you greater access to
              and control over your personal information. You may review,
              change, or terminate your account at any time.
            </p>
            <p className="sm">
              In some regions (like the European Economic Area), you have
              certain rights under applicable data protection laws. These may
              include the right (i) to request access and obtain a copy of your
              personal information, (ii) to request rectification or erasure;
              (iii) to restrict the processing of your personal information; and
              (iv) if applicable, to data portability. In certain circumstances,
              you may also have the right to object to the processing of your
              personal information. To make such a request, please use{" "}
              <a className={classes.Link} href="#contacts">
                the contact details
              </a>{" "}
              provided below. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </p>
            <p className="sm">
              If we are relying on your consent to process your personal
              information, you have the right to withdraw your consent at any
              time. Please note however that this will not affect the lawfulness
              of the processing before its withdrawal.
            </p>
            <p className="sm">
              If you are resident in the European Economic Area and you believe
              we are unlawfully processing your personal information, you also
              have the right to complain to your local data protection
              supervisory authority. You can find their contact details here:{" "}
              <a
                className={classes.Link}
                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                target="_blank"
                rel="noreferrer"
              >
                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              </a>
              .
            </p>
            <p className="sm">
              <strong>Cookies and similar technologies:</strong> Most Web
              browsers are set to accept cookies by default. If you prefer, you
              can usually choose to set your browser to remove cookies and to
              reject cookies. If you choose to remove cookies or reject cookies,
              this could affect certain features or services of our Services. To
              opt-out of interest-based advertising by advertisers on our
              Services visit{" "}
              <a
                className={classes.Link}
                href="https://www.aboutads.info/choices"
                target="_blank"
                rel="noreferrer"
              >
                https://www.aboutads.info/choices
              </a>
              .
            </p>
          </section>
          <section className={classes.Section} id="data-breach">
            <h4 className="h4">10. DATA BREACH</h4>
            <p className="sm">
              A privacy breach occurs when there is unauthorized access to or
              collection, use, disclosure or disposal of personal information.
              You will be notified about data breaches when Rebase believes you
              are likely to be at risk of serious harm. For example, a data
              breach may be likely to result in serious financial harm or harm
              to your mental or physical well-being. In the event that Rebase
              becomes aware of a security breach which has resulted or may
              result in unauthorized access, use or disclosure of personal
              information Rebase will promptly investigate the matter and notify
              the applicable Supervisory Authority not later than 72 hours after
              having become aware of it, unless the personal data breach is
              unlikely to result in a risk to the rights and freedoms of natural
              persons.
            </p>
          </section>
          <section className={classes.Section} id="do-not-truck">
            <h4 className="h4">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
            <p className="sm">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. No
              uniform technology standard for recognizing and implementing DNT
              signals has been finalized. As such, we do not currently respond
              to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future,
              we will inform you about that practice in a revised version of
              this privacy policy.
            </p>
          </section>
          <section className={classes.Section} id="california">
            <h4 className="h4">
              12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h4>
            <p className="sm">
              <strong>In Short:</strong> Yes, if you are a resident of
              California, you are granted specific rights regarding access to
              your personal information.
            </p>
            <p className="sm">
              California Civil Code Section 1798.83, also known as the “Shine
              The Light” law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p className="sm">
              If you are under 18 years of age, reside in California, and have a
              registered account with the Services, you have the right to
              request removal of unwanted data that you publicly post on the
              Services. To request removal of such data, please contact us using
              the contact information provided below, and include the email
              address associated with your account and a statement that you
              reside in California. We will make sure the data is not publicly
              displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from our systems.
            </p>
          </section>
          <section className={classes.Section} id="updates">
            <h4 className="h4">13. DO WE MAKE UPDATES TO THIS POLICY?</h4>
            <p className="sm">
              <strong>In Short:</strong> Yes, we will update this policy as
              necessary to stay compliant with relevant laws.
            </p>
            <p className="sm">
              We may update this privacy policy from time to time. The updated
              version will be indicated by an updated “Revised” date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy policy, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy policy frequently to be informed of how we are
              protecting your information.
            </p>
          </section>
          <section className={classes.Section} id="contacts">
            <h4 className="h4">
              14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
            </h4>
            <p className="sm">
              If you have questions or comments about this policy, you may
              contact us by email at{" "}
              <a className={classes.Link} href="mailto:privacy@rebase.agency">
                privacy@rebase.agency
              </a>
              .
            </p>
          </section>
        </div>
      </Layout>
    </>
  );
};
