import classNames from "classnames";
import classes from "./contact-result.module.css";
import { Rebase } from "../../../assets/svg";

export const ContactResult = ({ status }: { status: boolean }) => {
  return (
    <main className={classes.Main}>
      <h5 className={classNames("h5", classes.HeadLine)}>
        {status
          ? "Thank you! We’ve got you request."
          : "Unfortunately, there was an error when submitting the form."}
      </h5>
      <div className={classes.TextWrap}>
        <p className={classNames(classes.Text, "md")}>
          {status
            ? "We will contact you shortly to discuss the project. It usually takes no more than 24 hours."
            : "Please try submitting it again. We are aware of the problem and will fix it as soon as possible."}
        </p>
        {!status && (
          <button type="submit" className={classes.Button}>
            Try again
          </button>
        )}
      </div>
      <div className={classes.Rebase}>
        <Rebase />
      </div>
    </main>
  );
};
