import { Logo } from "../../assets/svg";
import classes from "./header.module.css";

export const Header = () => {
  return (
    <header className={classes.Header}>
      <div className={classes.HeaderInner}>
        <a href="/" className={classes.Logo}>
          <Logo />
        </a>
        <a href="https://t.me/RebaseBot" target="_blank" rel="noreferrer">
          <p className="link md">Telegram</p>
        </a>
      </div>
    </header>
  );
};
