export const CloseIcon = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1817_785)">
      <path
        d="M35.9446 11.1959L24.7487 22.3918L13.5529 11.1959L11.1959 13.5529L22.3917 24.7488L11.1959 35.9446L13.5529 38.3017L24.7487 27.1058L35.9446 38.3017L38.3016 35.9446L27.1058 24.7488L38.3016 13.5529L35.9446 11.1959Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1817_785">
        <rect
          width="35"
          height="35"
          fill="white"
          transform="translate(0 24.7488) rotate(-45)"
        />
      </clipPath>
    </defs>
  </svg>
);
