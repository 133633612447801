import { useEffect, useState } from "react";
import classes from "./cookie.module.css";
import classNames from "classnames";

const local = "rebase-cookie-accepted";

export const Cookie = () => {
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    const localAccepted = localStorage.getItem(local);
    setAccepted(!!localAccepted);
  }, []);

  return !accepted ? (
    <div className={classes.Cookie}>
      <p>
        We use cookies. By remaining on the website, you accept the{" "}
        <a className={classes.Link} href="/cookie-policy">
          Cookie Policy.
        </a>
      </p>
      <button
        className={classNames(classes.Button, "sm")}
        onClick={() => {
          setAccepted(true);
          localStorage.setItem(local, "true");
        }}
      >
        <p className="bold">OK</p>
      </button>
    </div>
  ) : null;
};
