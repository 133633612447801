import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./router/Router";
import { env } from "./core/env";

export function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://plausible.rebase.agency/js/script.js";
    script.dataset.domain = env.URL;
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}
