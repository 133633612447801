import { useState, useEffect } from "react";
import { CasesData } from "../entities/types";
import { apiInstance } from "../shared/api";

export const useCases = () => {
  const [cases, setCases] = useState<CasesData>({
    items: [],
    pagination: { page: 0, pages: 0, perPage: 0, total: 0 },
  });

  useEffect(() => {
    apiInstance
      .get("/cases")
      .then((response) => {
        setCases(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return cases;
};
