import classNames from "classnames";
import classes from "./card.module.css";
import { Case } from "../../entities/types";
import { RefObject, useContext, useEffect, useRef } from "react";
import { CursorContext } from "../../context";

export const Card = ({ data }: { data: Case }) => {
  const card = useRef() as RefObject<HTMLAnchorElement>;
  const cursorContext = useContext(CursorContext);

  useEffect(() => {
    if (card && card.current && cursorContext) {
      card.current.addEventListener("mouseover", cursorContext.onOver);
      card.current.addEventListener("mouseout", cursorContext.onOut);
    }

    return () => {
      if (card && card.current && cursorContext) {
        card.current.removeEventListener("mouseover", cursorContext.onOver);
        card.current.removeEventListener("mouseout", cursorContext.onOut);
      }
    };
  }, [card, cursorContext]);

  return (
    <a
      className={classNames(
        classes.Card,
        data.externalLink && classes.Active,
        data.externalLink && "active-card",
      )}
      ref={data.externalLink ? card : null}
      target="_blank"
      href={data.externalLink}
      rel="noreferrer"
      id={data.externalLink ? "active-card" : "card"}
    >
      <img src={data.illustrationUrl} className={classes.Img} alt={data.name} />
      <div className={classes.Text}>
        <p className={classNames(classes.Description, "md")}>
          {data.description}
        </p>
        <p className={classNames(classes.Title, "md")}>{data.name}</p>
      </div>
    </a>
  );
};
