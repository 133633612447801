export const Telegram = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM11.3942 8.12069C10.3243 8.5657 8.18597 9.48677 4.97925 10.8839C4.45853 11.0909 4.18576 11.2935 4.16092 11.4916C4.11895 11.8264 4.53817 11.9582 5.10903 12.1377C5.18668 12.1622 5.26715 12.1875 5.34963 12.2142C5.91127 12.3968 6.66677 12.6104 7.05953 12.6188C7.4158 12.6265 7.81344 12.4797 8.25244 12.1782C11.2486 10.1558 12.7952 9.13349 12.8923 9.11146C12.9608 9.09591 13.0557 9.07636 13.1201 9.13353C13.1843 9.19068 13.1781 9.29894 13.1712 9.328C13.1297 9.50501 11.4841 11.0349 10.6325 11.8267C10.367 12.0734 10.1788 12.2485 10.1403 12.2885C10.054 12.3781 9.96609 12.4628 9.88167 12.5442C9.3599 13.0472 8.9686 13.4244 9.9033 14.0404C10.3526 14.3364 10.712 14.5811 11.0706 14.8253C11.4622 15.0921 11.8528 15.3581 12.3581 15.6894C12.4869 15.7738 12.6098 15.8614 12.7297 15.9468C13.1854 16.2717 13.5949 16.5636 14.1007 16.517C14.3947 16.49 14.6983 16.2136 14.8525 15.3894C15.2169 13.4413 15.9331 9.22029 16.0987 7.4809C16.1133 7.3285 16.095 7.13347 16.0804 7.04786C16.0657 6.96225 16.0351 6.84027 15.9238 6.74997C15.792 6.64303 15.5886 6.62048 15.4975 6.62207C15.0838 6.62937 14.4491 6.85008 11.3942 8.12069Z"
      fill="#222222"
    />
  </svg>
);
