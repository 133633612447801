import { Helmet } from "react-helmet";
import React from "react";

export const CoreHead = ({ title }: { title: string }) => {
  const description = "";
  return (
    <Helmet>
      <title>{`Rebase${title ? ` | ${title}` : ""}`}</title>
      <meta
        property="og:title"
        content={`Rebase${title ? ` | ${title}` : ""}`}
      />
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicons/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicons/safari-pinned-tab.svg"
        color="#000000"
      />

      <meta name="msapplication-TileColor" content="#2d89ef" />
      <meta name="theme-color" content="#000000"></meta>

      <link rel="icon" href="/favicon.ico" />
    </Helmet>
  );
};
