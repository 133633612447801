import { useContext, useEffect, useState } from "react";
import { CardsBlock } from "../../components/CardsBlock";
import { Layout } from "../../components/Layout";
import { Ticker } from "../../components/Ticker";
import { TopBlock } from "../../components/TopBlock";
import { CoreHead } from "../../components/core/CoreHead";
import classes from "./mainpage.module.css";
import { Contact } from "../../components/Contact";
import { CursorContext } from "../../context";

export const MainPage = () => {
  const [openForm, setOpenForm] = useState(false);
  const cursorPointer = useContext(CursorContext);

  useEffect(() => {
    cursorPointer?.setOpenedContactsForm(openForm);
  }, [cursorPointer, openForm]);

  return (
    <>
      {!openForm ? (
        <>
          <CoreHead title="Web3 solutions" />
          <Layout>
            <main className={classes.Main}>
              <div className={classes.Decorations}>
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className={classes.Content}>
                <TopBlock openForm={() => setOpenForm(true)} />
                <Ticker />
                <CardsBlock />
              </div>
            </main>
          </Layout>
        </>
      ) : (
        <Contact close={() => setOpenForm(false)} />
      )}
    </>
  );
};
