import { ArrowRight } from "../../assets/svg";
import classes from "./top-block.module.css";

export const TopBlock = ({ openForm }: { openForm: () => void }) => {
  return (
    <div className={classes.TopBlock}>
      <h1 className="h1">Trusted team of developers from the Web3 era</h1>
      <div className={classes.ButtonWrap}>
        <button className="btn" onClick={openForm}>
          <p className="md">Contact us</p>
          <ArrowRight />
        </button>
      </div>
    </div>
  );
};
