import { Cookie } from "../Cookie";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <div>
      <Header />
      {children}
      <Cookie />
      <Footer />
    </div>
  );
};
