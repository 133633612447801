export const Logo = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_39_197)">
      <path
        d="M41.5386 0.769226C42.8132 0.769226 43.8463 1.80242 43.8463 3.07692C43.8463 4.35142 42.8132 5.38461 41.5386 5.38461C40.2641 5.38461 39.231 4.35142 39.231 3.07692C39.231 1.80242 40.2641 0.769226 41.5386 0.769226Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M41.5386 3.07693H56.154C56.5788 3.07693 56.9232 3.42133 56.9232 3.84617V18.4615"
        stroke="black"
        strokeWidth="5.6"
      />
      <path
        d="M56.9229 16.1538C58.1975 16.1538 59.2306 17.187 59.2306 18.4615C59.2306 19.7361 58.1975 20.7692 56.9229 20.7692C55.6484 20.7692 54.6152 19.7361 54.6152 18.4615C54.6152 17.187 55.6484 16.1538 56.9229 16.1538Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M18.4615 59.2308C17.187 59.2308 16.1538 58.1976 16.1538 56.9231C16.1538 55.6485 17.187 54.6154 18.4615 54.6154C19.736 54.6154 20.7692 55.6485 20.7692 56.9231C20.7692 58.1976 19.736 59.2308 18.4615 59.2308Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M18.4618 56.9231H3.84638C3.42155 56.9231 3.07715 56.5787 3.07715 56.1538V41.5385"
        stroke="black"
        strokeWidth="5.6"
      />
      <path
        d="M3.07674 43.8462C1.80224 43.8462 0.769043 42.813 0.769043 41.5385C0.769043 40.2639 1.80224 39.2308 3.07674 39.2308C4.35124 39.2308 5.38443 40.2639 5.38443 41.5385C5.38443 42.813 4.35124 43.8462 3.07674 43.8462Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M21.3726 43.0769H27.05V33.5577H31.1933L36.2808 43.0769H42.5483L36.8446 32.6398C39.8996 31.3287 41.5911 28.6669 41.5911 24.9956C41.5911 19.6591 38.064 16.2238 31.9669 16.2238H21.3726V43.0769ZM27.05 28.9948V20.8654H30.8786C34.1566 20.8654 35.7432 22.3208 35.7432 24.9956C35.7432 27.6573 34.1566 28.9948 30.9049 28.9948H27.05Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_39_197">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
